export const designs = [
  {
    title: "Flights - Concept",
    year: 2024,
    img: "./flights_1.png",
    thumb: "./flights_thumb.png",
    text: "Flights est un concept d'une application pour iOS. Celle-ci propose une gestion simplifiée des vols pour les voyageurs. Elle permet aux utilisateurs de suivre leur vol actuel, explorer des destinations à visiter et réserver de nouveaux vols facilement. L'interface est minimaliste, intuitive et visuellement attrayante, utilisant des éléments de design modernes et des icônes familières.",
    images: ["./flights_3.png", "./flights_widget.png"],
  },
  {
    title: "Social Recipe - Concept",
    year: 2024,
    img: "./social_recipe.png",
    thumb: "./social_recipe_thumb.png",
    text: "Social Recipe est un concept d'une application pour iOS. Ce réseau social permet aux utilisateurs de découvrir, partager et échanger des recettes culinaires. Elle combine les fonctionnalités d'un réseau social classique avec une plateforme de découverte culinaire, offrant une expérience interactive et engageante aux passionnés de cuisine.",
    images: [],
  },
  {
    title: "Discovery",
    year: 2024,
    img: "./discovery.png",
    thumb: "./discovery_thumb.png",
    text: "",
    images: [],
  },
  {
    title: "Workspace",
    year: 2024,
    img: "./workspace.png",
    thumb: "./workspace_thumb.png",
    text: "",
    images: [],
  },
  {
    title: "Aurora",
    year: 2024,
    img: "./aurora.png",
    thumb: "./aurora_thumb.png",
    color: "green",
    text: "",
    images: [],
  },
  {
    title: "Digital World",
    year: 2024,
    img: "./digital_world.png",
    thumb: "./digital_world_thumb.png",
    text: "",
    images: [],
  },
  {
    title: "GreenHarvest Solutions",
    year: 2024,
    img: "./hero_agriculture.png",
    thumb: "./hero_agriculture_thumb.png",
    text: "",
    images: [],
  },
  {
    title: "Space Travel",
    year: 2024,
    img: "./space.png",
    thumb: "./space_thumb.png",
    color: "purple",
    text: "",
    images: [],
  },
  {
    title: "Electronic Arts - Redesign",
    year: 2024,
    img: "./ea_redesign_2.png",
    thumb: "./ea_thumb.png",
    color: "purple",
    text: "",
    images: [],
  },
  {
    title: "Adventure Horizon",
    year: 2024,
    img: "./adventure_horizon.png",
    thumb: "./adventure_horizon_thumb.png",
    text: "",
    images: [],
  },
];
